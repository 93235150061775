<template>
  <div class="body">
    <templateHeader />
    <div>
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <templateFooter />
  </div>
</template>

<script>
import templateHeader from "./templateHeader";
import templateFooter from "./templateFooter";
import {mapState} from "vuex";
// import Index from "./index";
export default {
  name: "Home",
  components: {
    templateHeader,
    templateFooter,
    // Index
  },
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {};
  },

  mounted() {
    let _token = JSON.stringify(this.$route.query.taken);
    let _tenantId = JSON.stringify(this.$route.query.domainTid);
    if (_token) {
      window.localStorage.setItem("token", _token);
    }
    if(_tenantId){
      window.localStorage.setItem("domainTid", _tenantId);
    }
  },
};
</script>
<style lang="scss" scoped>
  .body{
    //background-color: #f4f4f4;
    color: #5c5c5c;
    //background-image: linear-gradient( #CCF7FC, #ffffff);
    font-family: "微软雅黑";
    font-size: 14px;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background: url("../images/ninth_bg2.jpg") no-repeat;
  }
</style>
