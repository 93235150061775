// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/ninth_bg2.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".body[data-v-1fcb4140]{color:#5c5c5c;font-family:微软雅黑;font-size:14px;width:100%;height:100vh;overflow-y:scroll;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}", ""]);
// Exports
module.exports = exports;
